import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { Actions, ofType } from '@ngrx/effects';
import { PLUrlsService } from '@common/services/pl-http';
import { AppState } from '@app/store';
import { SessionActions } from '../session/store';
import { Router } from '@angular/router';

@Component({
  selector: 'pl-duplicated-provider',
  templateUrl: 'duplicated-provider.component.html',
  styleUrls: ['duplicated-provider.component.less'],
})
export class DuplicatedProviderComponent implements OnInit, OnDestroy {
  sub: Subscription;
  landingUrl: string;
  loading = false;

  constructor(
    private plUrls: PLUrlsService,
    private store: Store<AppState>,
    private actions$: Actions,
    private router: Router,
  ) {
    this.landingUrl = this.plUrls.urls.landingFE;
  }

  ngOnInit() {
    this.sub = this.listenToKickDuplicatedsSuccess();
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  continueHere() {
    this.loading = true;
    this.store.dispatch(SessionActions.kickDuplicateds());
  }

  listenToKickDuplicatedsSuccess() {
    return this.actions$
      .pipe(ofType(SessionActions.kickDuplicatedsSuccess))
      .subscribe(() => {
        this.router.navigate(['']);
      });
  }
}
