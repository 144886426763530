import { Injectable, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

export interface WaitingRoomCredentials {
  is_kanga_room: boolean;
  tokbox_api_key: string;
  tokbox_session_id: string;
  tokbox_token: string;
}

@Injectable({ providedIn: 'root' })
export class WaitingRoomHelperService {
  clinicianUsername: string;
  sessionId: string;
  waitingRoomRoute = [];
  private _currentCredentials: WaitingRoomCredentials;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private zone: NgZone,
  ) {}

  getClinicianUsername() {
    return this.clinicianUsername;
  }

  redirectToClinicianRoom = () => {
    this.router.navigate([...this.getWaitingRoomRoute(), 'room'], {
      replaceUrl: true,
    });
  };

  redirectToStudentLogin() {
    this.router.navigate([...this.getWaitingRoomRoute()], {});
  }

  redirectToWaitingRoom() {
    this.router.navigate([...this.getWaitingRoomRoute(), 'waiting-room'], {
      replaceUrl: true,
    });
  }

  getWaitingRoomRoute() {
    if (this.sessionId) {
      return ['sessionId', this.sessionId];
    }
    return [this.clinicianUsername];
  }

  initialize(credentials: WaitingRoomCredentials) {
    this._currentCredentials = credentials;
  }

  isInitialized() {
    return Boolean(this._currentCredentials);
  }

  get currentCredentials() {
    return this._currentCredentials;
  }
}
