import { QueuesService as PlatformQueuesService } from '@library/common/services/platform/queues.service';
import { Injectable } from '@angular/core';
import { FirebaseService } from '@root/src/app/common/firebase/firebase.service';
import { Observable, combineLatest, from, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Activity } from '@room/app/store';
import { PLGuidService } from '@root/src/app/common/services/GuidService';

export interface Queues {
  order: string[];
  items: Record<string, QueueItem>;
}

export interface QueueItem {
  id?: string;
  name: string;
  description?: string;
  deletedDate?: string;
  created?: number;
  edited?: number;
  order: string[];
  items: Record<string, Activity>;
}

export interface QueuesActivities {
  [queueId: string]: Activity[];
}

@Injectable({ providedIn: 'root' })
export class QueuesService {
  queuesRef: firebase.database.Reference;

  constructor(
    private firebaseService: FirebaseService,
    private guidService: PLGuidService,
    private platformQueueService: PlatformQueuesService,
  ) {}

  public initializeQueuesService(): Observable<boolean> {
    this.queuesRef = this.firebaseService.getRoomRef('activities/queues');
    return this.platformQueueService.migrate();
  }

  /**
   * Retrieves the queues as an Observable.
   * @returns An Observable that emits the queues.
   */
  public getQueues$(): Observable<Queues> {
    return from(this.queuesRef.once('value')).pipe(
      map(snapshot => {
        return snapshot.val() ? snapshot.val() : { items: {}, order: [] };
      }),
      map((queues: Queues) => {
        Object.entries(queues.items)
          .filter(([_, queue]) => queue.deletedDate)
          .forEach(([queueId, _]) => {
            delete queues.items[queueId];
            queues.order = (queues.order ?? []).filter(id => id !== queueId);
          });
        return queues;
      }),
    );
  }

  /**
   * Retrieves a queue item from the specified queue.
   *
   * @param queueId - The ID of the queue item to retrieve.
   * @returns An observable that emits the retrieved queue item.
   */
  public getQueue$(queueId: string): Observable<QueueItem> {
    return from(this.queuesRef.child(`items/${queueId}`).once('value')).pipe(
      map(snapshot => ({ ...snapshot.val(), id: queueId })),
    );
  }

  /**
   * Creates a new queue item.
   *
   * @param queue - The queue item to create.
   * @returns An observable that emits the ID of the created queue item.
   */
  public createQueue$(queueDetails: QueueItem): Observable<QueueItem> {
    const queueId = this.guidService.generateUUID();
    const queue = { ...queueDetails, created: Date.now(), id: queueId };
    return from(this.queuesRef.update({ [`items/${queueId}`]: queue })).pipe(
      switchMap(() => this.getQueues$()),
      switchMap(queues =>
        from(
          this.queuesRef.child('order').set([...(queues.order ?? []), queueId]),
        ),
      ),
      map(() => ({ ...queue, id: queueId })),
    );
  }

  /**
   * Updates a queue item.
   *
   * @param queueId - The ID of the queue item to update.
   * @param queueData - The partial data to update the queue item with.
   * @returns An observable that emits the ID of the updated queue item.
   */
  public updateQueue$(
    queueId: string,
    queueData: Partial<QueueItem>,
  ): Observable<QueueItem> {
    return from(
      this.queuesRef.child(`items/${queueId}`).update(queueData),
    ).pipe(switchMap(() => this.getQueue$(queueId)));
  }

  /**
   * Deletes a queue item (soft-delete).
   *
   * @param queueId - The ID of the queue item to delete.
   * @returns An observable that emits the ID of the deleted queue item.
   */
  public deleteQueue$(queueId: string): Observable<string> {
    return from(
      this.queuesRef.child(`items/${queueId}/deletedDate`).set(Date.now()),
    ).pipe(
      switchMap(() => this.getQueues$()),
      switchMap(queues =>
        from(
          this.queuesRef
            .child('order')
            .set((queues.order ?? []).filter(id => id !== queueId)),
        ),
      ),
      map(() => queueId),
    );
  }

  /**
   * Restores a deleted queue item (from its soft delete state).
   *
   * @param queueId - The ID of the queue item to restore.
   * @returns An observable that emits the ID of the restored queue item.
   */
  public restoreQueue$(queueId: string): Observable<QueueItem> {
    return from(
      this.queuesRef.child(`items/${queueId}/deletedDate`).remove(),
    ).pipe(
      switchMap(() => this.getQueues$()),
      switchMap(queues =>
        from(
          this.queuesRef.child('order').set([...(queues.order ?? []), queueId]),
        ),
      ),
      switchMap(() => this.getQueue$(queueId)),
    );
  }

  /**
   * Updates the order of queues.
   * @param order - The new order of queues.
   * @returns An Observable that emits the updated order of queues.
   */
  public updateQueuesOrder$(order: string[]): Observable<string[]> {
    return from(this.queuesRef.child('order').set(order)).pipe(
      map(() => order),
    );
  }

  /**
   * Adds an activity to a queue.
   *
   * @param queueId - The ID of the queue.
   * @param activity - The activity to add.
   * @returns An Observable that emits the ID of the added activity.
   */
  public addActivity$(queueId: string, activity: Activity): Observable<string> {
    // First we get the queue, we need its order to update it
    return this.getQueue$(queueId).pipe(
      // Then we update the queue with the new activity
      switchMap(queue =>
        combineLatest([
          from(
            this.queuesRef
              .child(`items/${queueId}/items/${activity.activityId}`)
              .set(activity),
          ),
          of(queue),
        ]),
      ),
      // Then we update the order of the queue
      switchMap(([_, queue]) => {
        const order = queue.order ? queue.order : [];
        return from(
          this.queuesRef
            .child(`items/${queueId}/order`)
            .set([...order, activity.activityId]),
        );
      }),
      map(() => activity.activityId),
    );
  }

  /**
   * Removes an activity from a queue.
   *
   * @param queueId - The ID of the queue to remove the activity from.
   * @param activityId - The ID of the activity to remove.
   * @returns An observable that emits the ID of the removed activity.
   */
  public removeActivity$(
    queueId: string,
    activityId: string,
  ): Observable<string> {
    // First we get the queue, we need its order to update it
    return this.getQueue$(queueId).pipe(
      // Then we remove the activity from the queue
      switchMap(queue =>
        combineLatest([
          from(
            this.queuesRef
              .child(`items/${queueId}/items/${activityId}`)
              .remove(),
          ),
          of(queue),
        ]),
      ),
      // Then we update the order of the queue
      switchMap(([_, queue]) => {
        const order = queue.order ? queue.order : [];
        return from(
          this.queuesRef
            .child(`items/${queueId}/order`)
            .set(order.filter(id => id !== activityId)),
        );
      }),
      map(() => activityId),
    );
  }

  /**
   * Adds multiple activities to multiple queues.
   *
   * @param queuesActivities - A dictionary indicating the queues and the corresponding activities.
   * @returns An Observable that emits an array of strings representing the IDs of the updated queues.
   */
  public addActivitiesToQueues$(
    queuesActivities: QueuesActivities,
  ): Observable<string[]> {
    const updatedQueuesIds = [];
    // We create an update entry for each activity in each queue
    const updates = Object.keys(queuesActivities).reduce((acc, queueId) => {
      const activities = queuesActivities[queueId];
      activities.forEach(activity => {
        this.validateActivity(activity);
        acc[`items/${queueId}/items/${activity.activityId}`] = activity;
        if (!updatedQueuesIds.includes(queueId)) {
          updatedQueuesIds.push(queueId);
        }
      });
      return acc;
    }, {});
    // We update the queues
    return from(this.queuesRef.update(updates)).pipe(
      // Then we get every updated queue
      switchMap(() => {
        const updatedQueues = updatedQueuesIds.map(queueId =>
          this.getQueue$(queueId),
        );
        return combineLatest(updatedQueues);
      }),
      // Then we update the order of each queue
      switchMap(updatedQueues => {
        const orderUpdates = updatedQueues.map(queue => {
          const order = queue.order ? queue.order : [];
          const activities = queuesActivities[queue.id];
          activities.forEach(activity => {
            if (!order.includes(activity.activityId)) {
              order.push(activity.activityId);
            }
          });
          return this.queuesRef.child(`items/${queue.id}/order`).set(order);
        });
        return combineLatest(orderUpdates);
      }),
      map(() => updatedQueuesIds),
    );
  }

  public removeActivitiesFromQueues$(
    queuesActivities: QueuesActivities,
  ): Observable<string[]> {
    const updatedQueuesIds = [];
    // We create an update entry for each activity in each queue
    const updates = Object.keys(queuesActivities).reduce((acc, queueId) => {
      const activities = queuesActivities[queueId];
      activities.forEach(activity => {
        acc[`items/${queueId}/items/${activity.activityId}`] = null;
        if (!updatedQueuesIds.includes(queueId)) {
          updatedQueuesIds.push(queueId);
        }
      });
      return acc;
    }, {});
    // We update the queues
    return from(this.queuesRef.update(updates)).pipe(
      // Then we get every updated queue
      switchMap(() => {
        const updatedQueues = updatedQueuesIds.map(queueId =>
          this.getQueue$(queueId),
        );
        return combineLatest(updatedQueues);
      }),
      // Then we update the order of each queue
      switchMap(updatedQueues => {
        const orderUpdates = updatedQueues.map(queue => {
          const order = queue.order ? queue.order : [];
          const activities = queuesActivities[queue.id];
          activities.forEach(activity => {
            order.splice(order.indexOf(activity.activityId), 1);
          });
          return this.queuesRef.child(`items/${queue.id}/order`).set(order);
        });
        return combineLatest(orderUpdates);
      }),
      map(() => updatedQueuesIds),
    );
  }

  /**
   * Updates the order of activities in a queue.
   *
   * @param queueId - The ID of the queue.
   * @param order - The new order of activities.
   * @returns An Observable that emits the updated order of activities.
   */
  public updateActivitiesOrder$(
    queueId: string,
    order: string[],
  ): Observable<string[]> {
    return from(this.queuesRef.child(`items/${queueId}/order`).set(order)).pipe(
      map(() => order),
    );
  }

  // cannot write undefined values to firebase
  validateActivity(activity: Activity) {
    const keys = Object.keys(activity);
    keys.forEach(key => {
      if (activity[key] === undefined || activity[key] === null) {
        activity[key] = '';
      }
    });
  }
}
