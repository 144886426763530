import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CurrentUserService } from '@common/models/current-user.service';
import { PaginatedApiResponse, PlatformUUIDModel } from './platform.model';

export abstract class AbstractPlatformService<T extends PlatformUUIDModel> {
  model: T;
  jwt: string;

  protected constructor(
    protected http: HttpClient,
    protected baseUrl: string,
    protected currentUserService: CurrentUserService,
  ) {}

  get userUuid() {
    return this.currentUserService.user.uuid;
  }

  protected getHeaders() {
    this.jwt = this.currentUserService.jwt
      ? this.currentUserService.jwt
      : this.currentUserService.user.token;
    if (!this.jwt) {
      return {};
    }
    return {
      Authorization: `JWT ${this.jwt}`,
    };
  }

  public get(id: number): Observable<T> {
    const headers = this.getHeaders();
    return this.http.get(`${this.baseUrl}/${id}`, {
      headers,
    }) as Observable<T>;
  }

  getBySlug(slug: string): Observable<T> {
    const headers = this.getHeaders();
    return this.http.get(`${this.baseUrl}/slug/${slug}/`, {
      headers,
    }) as Observable<T>;
  }

  public getList(
    page = 1,
    limit = 10,
    extraParams = {},
  ): Observable<PaginatedApiResponse> {
    const params = {
      page,
      limit,
      disabled: false,
      private: false,
      ...extraParams,
    };
    const headers = this.getHeaders();
    return this.http.get(`${this.baseUrl}/`, {
      params,
      headers,
    }) as Observable<PaginatedApiResponse>;
  }

  public put(id: number, data: T): Observable<T> {
    const headers = this.getHeaders();
    return this.http.put(`${this.baseUrl}/${id}/`, data, {
      headers,
    }) as Observable<T>;
  }

  public patch(id: number, data: Partial<T>): Observable<T> {
    const headers = this.getHeaders();
    return this.http.patch(`${this.baseUrl}/${id}/`, data, {
      headers,
    }) as Observable<T>;
  }

  public post(data: Partial<T>, extraOptions = {}): Observable<T> {
    const headers = this.getHeaders();
    return this.http.post(`${this.baseUrl}/`, data, {
      headers,
      ...extraOptions,
    }) as Observable<T>;
  }

  public delete(id: number): Observable<T> {
    const headers = this.getHeaders();
    return this.http.delete(`${this.baseUrl}/${id}/`, {
      headers,
    }) as Observable<T>;
  }
}
