import { AppState } from '@root/src/app/store';
import { Queues } from '@library/common/services/queues.service';
import { createSelector } from '@ngrx/store';

export interface LibraryState {
  userRoom: UserRoom;
  userQueues: Queues;
  queuesServiceInitialized: boolean;
  lastDeletedQueueId: string;
}

export interface UserRoom {
  roomName: string;
  firebase_auth_token: string;
  firebase_app_config: FirebaseAppConfig;
  firebase_baseurl: string;
  queues_migrated: boolean;
}

// This is duplicated, considering moving to a shared location
export interface FirebaseAppConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  storageBucket: string;
  messagingSenderId: string;
}

export const selectLibraryState = (state: AppState) => state.library;
export const selectUserQueues = createSelector(
  selectLibraryState,
  (state: LibraryState) => state.userQueues,
);
export const selectQueuesServiceInitialized = createSelector(
  selectLibraryState,
  (state: LibraryState) => state.queuesServiceInitialized,
);
export const selectLastDeletedQueueId = createSelector(
  selectLibraryState,
  (state: LibraryState) => state.lastDeletedQueueId,
);
export const selectQueuesMigrated = createSelector(
  selectLibraryState,
  (state: LibraryState) => state.userRoom?.queues_migrated,
);
