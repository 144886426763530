import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DuplicatedProviderComponent } from './modules/room/duplicated-provider/duplicated-provider.component';
import { validUserGuard } from './modules/room/room-guard.service';

const routes: Routes = [
  {
    path: 'suggested-resources',
    loadChildren: () =>
      import('./modules/suggested-resources/').then(
        m => m.SuggestedResourcesModule,
      ),
  },
  {
    path: 'library',
    canActivate: [validUserGuard],
    loadChildren: () => import('./modules/library').then(m => m.LibraryModule),
  },
  {
    path: 'pl',
    loadChildren: () => import('./modules/pl').then(m => m.PLModule),
  },
  {
    path: 'invalid-room',
    pathMatch: 'full',
    loadChildren: () =>
      import('./modules/room/invalid-room').then(m => m.InvalidRoomModule),
  },
  {
    path: 'duplicated-provider',
    pathMatch: 'full',
    component: DuplicatedProviderComponent,
  },
  {
    path: 'observe/:clinician_username',
    loadChildren: () => import('./modules/room').then(m => m.RoomModule),
    canActivate: [validUserGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    loadChildren: () => import('./modules/room').then(m => m.RoomModule),
    canActivate: [validUserGuard],
  },
  {
    path: 'session',
    loadChildren: () =>
      import('./modules/student-login').then(m => m.PLStudentLoginModule),
  },
  {
    path: ':clinician_username',
    loadChildren: () =>
      import('./modules/student-login').then(m => m.PLStudentLoginModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      paramsInheritanceStrategy: 'always',
      // enableTracing: true, // <-- debugging purposes only
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
