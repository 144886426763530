import { Injectable } from '@angular/core';
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';
import { FirebaseActions } from '@common/firebase/store';
import { LibraryActions } from '@library/store';
import {
  QueueItem,
  QueuesService,
} from '@library/common/services/queues.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { EditQueueDetailsComponent } from '@library/my-library/queues/edit-queue-details/edit-queue-details.component';
import { Notifications } from '@root/src/app/common/services/notifications.service';
import { of } from 'rxjs';
import { MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Injectable()
export class LibraryEffects {
  setUserRoom$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.setUserRoom),
      map(({ rooms }) => {
        const room = rooms[0];
        return LibraryActions.setUserRoomSuccess({
          userRoom: {
            roomName: room.name,
            firebase_app_config: room.firebase_app_config,
            firebase_baseurl: room.firebase_baseurl,
            firebase_auth_token: room.firebase_auth_token,
            queues_migrated: room.queues_migrated,
          },
        });
      }),
    );
  });

  initializeFirebase$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.setUserRoomSuccess),
      map(({ userRoom }) => {
        const {
          roomName,
          firebase_app_config,
          firebase_baseurl,
          firebase_auth_token,
        } = userRoom;

        return {
          roomName,
          customToken: firebase_auth_token,
          apiKey: firebase_app_config.apiKey,
          authDomain: firebase_app_config.authDomain,
          databaseURL: firebase_baseurl,
          storageBucket: firebase_app_config.storageBucket,
          messagingSenderId: firebase_app_config.messagingSenderId,
        };
      }),
      map(config => FirebaseActions.initialize({ config })),
    );
  });

  initializeQueuesService$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FirebaseActions.setInitialized),
      map(() => {
        this.queuesService.initializeQueuesService();
        return LibraryActions.setQueuesServiceInitialized({
          initialized: true,
        });
      }),
    );
  });

  fetchUserQueues$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.fetchUserQueues),
      switchMap(() => {
        return this.queuesService.getQueues$();
      }),
      map(userQueues => {
        return LibraryActions.fetchUserQueuesSuccess({ userQueues });
      }),
    );
  });

  createUserQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.createUserQueue),
      switchMap(({ queue }) => {
        return this.queuesService.createQueue$(queue);
      }),
      map(createdQueue => {
        return LibraryActions.createUserQueueSuccess({ queue: createdQueue });
      }),
    );
  });

  deleteUserQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.deleteUserQueue),
      switchMap(({ queueId, showNotification }) => {
        return this.queuesService
          .deleteQueue$(queueId)
          .pipe(map(() => ({ queueId, showNotification })));
      }),
      map(({ queueId, showNotification }) => {
        return LibraryActions.deleteUserQueueSuccess({
          queueId,
          showNotification: showNotification === false ? false : true,
        });
      }),
    );
  });

  showDeleteQueueNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.deleteUserQueueSuccess),
      switchMap(({ queueId, showNotification }) => {
        const obs$ = showNotification
          ? this.notificationsService
              .showPLSnackBar(
                {
                  title: 'Queue deleted',
                  actionLabel: 'Undo',
                  dismissible: true,
                  mode: 'dark',
                },
                {
                  horizontalPosition: 'left',
                  verticalPosition: 'bottom',
                },
              )
              .afterDismissed()
          : of({ dismissedByAction: false } as MatSnackBarDismiss);
        return obs$.pipe(map(response => ({ response, queueId })));
      }),
      map(({ response, queueId }) => {
        if (response.dismissedByAction) {
          return LibraryActions.deleteQueueNotificationDismissedByAction({
            queueId,
          });
        }
        return LibraryActions.deleteQueueNotificationDismissed();
      }),
    );
  });

  deleteQueueNotificationDismissedByAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.deleteQueueNotificationDismissedByAction),
      map(({ queueId }) => {
        return LibraryActions.restoreDeletedQueue({ queueId });
      }),
    );
  });

  restoreDeletedQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.restoreDeletedQueue),
      switchMap(({ queueId }) => {
        return this.queuesService.restoreQueue$(queueId);
      }),
      map(queue => {
        return LibraryActions.restoreDeletedQueueSuccess({ queue });
      }),
    );
  });

  showEditQueueDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.showEditQueueDialog),
      switchMap(({ queue, data }) => {
        const dialogRef: MatDialogRef<
          EditQueueDetailsComponent,
          Partial<QueueItem>
        > = this.dialog.open(EditQueueDetailsComponent, {
          data: { queue, ...data },
          width: '472px',
        });

        return dialogRef
          .afterClosed()
          .pipe(map(updatedQueue => ({ updatedQueue, queue })));
      }),
      map(({ updatedQueue, queue }) => {
        if (updatedQueue) {
          return LibraryActions.editUserQueue({
            queueId: queue.id,
            queue: updatedQueue,
          });
        } else {
          return LibraryActions.editQueueDialogDismissed();
        }
      }),
    );
  });

  editUserQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.editUserQueue),
      switchMap(({ queueId, queue }) => {
        return this.queuesService.updateQueue$(queueId, queue);
      }),
      map(editedQueue => {
        return LibraryActions.editUserQueueSuccess({ queue: editedQueue });
      }),
    );
  });

  editUserQueueSuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(LibraryActions.editUserQueueSuccess),
        map(({ queue }) => {
          this.notificationsService.showPLSnackBar(
            {
              title: `Queue ${queue.name} updated!`,
              dismissible: true,
              mode: 'dark',
            },
            {
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
            },
          );
        }),
      );
    },
    { dispatch: false },
  );

  duplicateQueue$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.duplicateQueue),
      switchMap(({ queue, navigateToIfUndoPath }) => {
        return this.queuesService
          .createQueue$({
            ...queue,
            name: `Copy of ${queue.name}`,
          })
          .pipe(
            map(duplicatedQueue => ({ duplicatedQueue, navigateToIfUndoPath })),
          );
      }),
      map(({ duplicatedQueue, navigateToIfUndoPath }) => {
        return LibraryActions.duplicateQueueSuccess({
          queue: duplicatedQueue,
          navigateToIfUndoPath,
        });
      }),
    );
  });

  showDuplicateQueueNotification$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.duplicateQueueSuccess),
      switchMap(({ queue, navigateToIfUndoPath }) => {
        return this.notificationsService
          .showPLSnackBar(
            {
              title: queue.name + ' has been duplicated',
              actionLabel: 'Undo',
              dismissible: true,
              mode: 'dark',
            },
            {
              horizontalPosition: 'left',
              verticalPosition: 'bottom',
            },
          )
          .afterDismissed()
          .pipe(map(response => ({ response, queue, navigateToIfUndoPath })));
      }),
      map(({ response, queue, navigateToIfUndoPath }) => {
        if (response.dismissedByAction) {
          return LibraryActions.duplicateQueueNotificationDismissedByAction({
            queueId: queue.id,
            navigateToIfUndoPath,
          });
        }
        return LibraryActions.duplicateQueueNotificationDismissed();
      }),
    );
  });

  duplicateQueueNotificationDismissedByAction$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.duplicateQueueNotificationDismissedByAction),
      map(({ queueId, navigateToIfUndoPath }) => {
        if (navigateToIfUndoPath) {
          this.router.navigate([navigateToIfUndoPath]);
        }
        return LibraryActions.deleteUserQueue({
          queueId,
          showNotification: false,
        });
      }),
    );
  });

  updateQueuesOrder$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(LibraryActions.updateQueuesOrder),
      switchMap(({ order }) => {
        return this.queuesService.updateQueuesOrder$(order);
      }),
      map(order => LibraryActions.updateQueuesOrderSuccess({ order })),
    );
  });

  constructor(
    private actions$: Actions,
    private queuesService: QueuesService,
    private dialog: MatDialog,
    private notificationsService: Notifications,
    private router: Router,
  ) {}
}
