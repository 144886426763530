import { createReducer, on } from '@ngrx/store';
import {
  AppConfigState,
  CentralContent,
  CentralContentType,
  LayoutMode,
} from './app.model';
import { AppActions } from './app.actions';
import { PlatformQueueItem } from '../../../library/common/services/platform/platform.model';

export const initialState: AppConfigState = {
  isClientClickMuted: false,
  isGlobalCursorShared: false,
  isGlobalCursorShareToggleDisabled: false,
  layoutMode: LayoutMode.compact,
  currentCentralContent: {
    contentType: CentralContentType.Empty,
  },
  isTimestampTrackingDisabled: false,
  activityHistory: [],
  restoreFromHistoryActivity: null,
  undoDeleteActivityHistory: [],
};

const isSameCentralContent = (old: CentralContent, current: CentralContent) => {
  const { contentType: oldType, contentDetail: oldDetail } = old ?? {};
  const { contentType: currentType, contentDetail: currentDetail } =
    current ?? {};

  if (oldType !== currentType) {
    return false;
  }
  let detailIdKey: string;

  if (currentType === CentralContentType.QueueItem) {
    detailIdKey = 'uuid';
  }

  if (currentType === CentralContentType.GameItem) {
    detailIdKey = 'name';
  }

  if (detailIdKey) {
    const oldDetailId = oldDetail?.[detailIdKey];
    const currentDetailId = currentDetail?.[detailIdKey];

    return oldDetailId === currentDetailId;
  }
  return oldDetail === currentDetail;
};

export const reducer = createReducer(
  initialState,
  on(
    AppActions.setGlobalCursorSharingSuccess,
    (state, { isGlobalCursorShared, isGlobalCursorShareToggleDisabled }) => {
      return {
        ...state,
        isGlobalCursorShared,
        isGlobalCursorShareToggleDisabled,
      };
    },
  ),
  on(AppActions.setClientMouseClickSuccess, (state, { isClientClickMuted }) => {
    return {
      ...state,
      isClientClickMuted,
    };
  }),
  on(AppActions.setLayoutModeSuccess, (state, { layoutMode }) => {
    return {
      ...state,
      layoutMode,
    };
  }),
  on(AppActions.updateFromRemote, (state, { data }) => {
    const newData = { ...data };
    if (
      isSameCentralContent(
        state.currentCentralContent,
        data?.currentCentralContent,
      )
    ) {
      delete newData.currentCentralContent;
    }
    const newState = {
      ...state,
      ...newData,
    };
    if (!newData.currentDrawerActivity) {
      newState.currentDrawerActivity = null;
    }
    return newState;
  }),
  // on restoreFromHistory action, set the activity to restore as the current drawer activity
  on(AppActions.restoreFromHistory, (state, { activity }) => {
    return {
      ...state,
      restoreFromHistoryActivity: activity,
    };
  }),
  on(
    AppActions.setCentralContentSuccess,
    (state, { contentDetail, contentType }) => {
      const activity = contentDetail;
      const activityHistory = state.activityHistory || [];
      if (contentType === CentralContentType.QueueItem) {
        // the activity should be at the front of the history, followed
        // by all other activities that are not the same as the activity.
        // The history should be capped at 50 items.
        let newActivityHistory = [
          activity,
          ...activityHistory.filter(
            a => (a as PlatformQueueItem).uuid !== activity?.uuid,
          ),
        ].slice(0, 50);
        return {
          ...state,
          activityHistory: newActivityHistory,
        };
      } else if (contentType === CentralContentType.GameItem) {
        // same as above, but filter on name instead of activityId
        let newActivityHistory = [
          activity,
          ...activityHistory.filter(
            a => a?.item?.name !== activity?.item?.name,
          ),
        ].slice(0, 50);
        return {
          ...state,
          activityHistory: newActivityHistory,
        };
      } else {
        return { ...state };
      }
    },
  ),
  on(
    AppActions.deleteActivityHistorySuccess,
    (state, { undoDeleteActivityHistory }) => {
      return {
        ...state,
        activityHistory: [],
        undoDeleteActivityHistory,
      };
    },
  ),
);
