import { Injectable } from '@angular/core';
import { AbstractPlatformService } from './abstract-platform.service';
import { PlatformQueue, PlatformQueueItem } from './platform.model';
import { HttpClient } from '@angular/common/http';
import { CurrentUserService } from '@root/src/app/common/models/current-user.service';
import { PLUrlsService } from '@root/src/app/common/services/pl-http';
import { QueueItemService } from './queue-item.service';
import { Observable } from 'rxjs';
import { DRFRoomModel } from '@root/src/app/common/models/DRF/DRFRoomModel.service';
import { delay, map, retryWhen, switchMap } from 'rxjs/operators';
import { LoggerService } from '@root/src/app/common/services/logger/logger.service';

export enum QueueItemType {
  ACTIVITY = 'activity',
  ASSESSMENT = 'assessment',
  GAME = 'game',
  INSTANT_YOUTUBE = 'instant_youtube',
  LESSON = 'lesson',
}

@Injectable({
  providedIn: 'root',
})
export class QueuesService extends AbstractPlatformService<PlatformQueue> {
  constructor(
    protected http: HttpClient,
    plUrls: PLUrlsService,
    protected currentUserService: CurrentUserService,
    private queueItemService: QueueItemService,
    private roomModel: DRFRoomModel,
    private loggerService: LoggerService,
  ) {
    super(http, `${plUrls.urls.platformFE}/api/v1/queue`, currentUserService);
  }

  getByUuid(uuid: string) {
    const headers = this.getHeaders();
    return this.http.get<PlatformQueue>(`${this.baseUrl}/uuid/${uuid}/`, {
      headers,
    });
  }

  move(id: number, newPosition: number) {
    const headers = this.getHeaders();
    return this.http.post(
      `${this.baseUrl}/${id}/move/`,
      {
        order: newPosition,
      },
      { headers },
    );
  }

  restore(id: number) {
    const headers = this.getHeaders();
    return this.http.post(`${this.baseUrl}/${id}/restore/`, {}, { headers });
  }

  addItem(queue: PlatformQueue, type: QueueItemType, id: number) {
    const headers = this.getHeaders();
    return this.queueItemService.post(
      {
        queue: queue.uuid,
        item_type: type,
        item_id: id,
      },
      { headers },
    );
  }

  addItems(
    queues: {
      queue: PlatformQueue;
      items: { type: QueueItemType; id: number }[];
    }[],
  ) {
    return this.queueItemService.bulkCreate(
      queues.map(({ queue, items }) => ({
        queue: queue.uuid,
        items: items.map(({ type, id }) => ({ item_type: type, item_id: id })),
      })),
    );
  }

  removeItem(id) {
    return this.queueItemService.delete(id);
  }

  removeItems(items: PlatformQueueItem[]) {
    return this.queueItemService.bulkDelete(items.map(item => item.uuid));
  }

  getItems(
    uuid: string,
    params: any = {},
    pageOptions?: { page: number; pageSize: number },
  ) {
    const page = pageOptions?.page || 1;
    const pageSize = pageOptions?.pageSize || 100;
    return this.queueItemService.getList(page, pageSize, {
      queue: uuid,
      ...params,
    });
  }

  moveItem(id: number, newPosition: number) {
    return this.queueItemService.move(id, newPosition);
  }

  // Copied from pl-queue.directive; not sure why it is so complex.
  getResourceType(activity) {
    let isAssessment: boolean;
    let type = QueueItemType.ACTIVITY;
    if (activity?.resource_uri) {
      isAssessment =
        activity.resource_uri.toLowerCase().indexOf('assessment') >= 0;
    } else {
      isAssessment = activity?.activity_type === 'assessment';
    }
    if (isAssessment) {
      type = QueueItemType.ASSESSMENT;
    } else if (activity?.type === 'lesson') {
      type = QueueItemType.LESSON;
    }
    return type;
  }

  duplicate(id: number, newName?: string): Observable<PlatformQueue> {
    const headers = this.getHeaders();
    const data = newName ? { name: newName } : {};
    return this.http.post<PlatformQueue>(
      `${this.baseUrl}/${id}/duplicate/`,
      data,
      {
        headers,
      },
    );
  }

  migrate(): Observable<boolean> {
    let retries = 10;
    const headers = this.getHeaders();
    return this.http.post(`${this.baseUrl}/migrate/`, {}, { headers }).pipe(
      switchMap(() => {
        return this.isRoomMigrated().pipe(
          map(migrated => {
            if (!migrated) {
              if (retries === 0) {
                // 30 seconds passed
                this.loggerService.logEvent('Slow queue migration', {
                  level: 'warning',
                  tags: {
                    user: this.currentUserService.user.uuid,
                  },
                });
              }
              retries--;
              throw new Error('Migration pending');
            } else {
              return true;
            }
          }),
          retryWhen(errors => errors.pipe(delay(3000))),
        );
      }),
      map(() => true),
    );
  }

  isRoomMigrated() {
    const rooms = this.roomModel.collectionModel;
    rooms.filter({
      user__userprofile__uuid: this.currentUserService.user.uuid,
    });
    return rooms
      .fetch({}, this.currentUserService.jwt)
      .pipe(map(res => Boolean(res?.[0]?.queues_migrated)));
  }
}
