import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CurrentUserService } from '@root/src/app/common/models/current-user.service';
import { PLUrlsService } from '@root/src/app/common/services/pl-http';
import { AbstractPlatformService } from './abstract-platform.service';
import { PlatformQueueItem } from './platform.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class QueueItemService extends AbstractPlatformService<PlatformQueueItem> {
  constructor(
    protected http: HttpClient,
    plUrls: PLUrlsService,
    currentUserService: CurrentUserService,
  ) {
    super(
      http,
      `${plUrls.urls.platformFE}/api/v1/queue-item`,
      currentUserService,
    );
  }

  move(id: number, newPosition: number) {
    const headers = this.getHeaders();
    return this.http.post(
      `${this.baseUrl}/${id}/move/`,
      {
        order: newPosition,
      },
      { headers },
    );
  }

  public bulkCreate(
    queues: {
      queue: string;
      items: { item_type: string; item_id: number }[];
    }[],
  ): Observable<PlatformQueueItem[]> {
    const headers = this.getHeaders();
    return this.http.post<PlatformQueueItem[]>(
      this.baseUrl + '/bulk_create/',
      {
        queues,
      },
      { headers },
    );
  }

  public bulkDelete(items: string[]): Observable<any> {
    const headers = this.getHeaders();
    return this.http.delete(this.baseUrl + '/bulk_delete/', {
      headers,
      body: { items },
    });
  }
}
